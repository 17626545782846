const NEW = 'Створений'
const CONFIRMED = 'Активний'
const BAN = 'Заблоковано на сервері'
const DELETED = 'Видалений'

export const STATUSES = Object.freeze([
  {
    title: NEW,
    id: 0,
    color: 'orange',
    icon: 'mdi-progress-pencil',
  },
  {
    title: CONFIRMED,
    id: 1,
    color: 'green',
    icon: 'mdi-check',
  },
  {
    title: BAN,
    id: 2,
    color: 'red',
    icon: 'mdi-lock-outline',
  },
  {
    title: DELETED,
    id: 3,
    color: 'grey',
    icon: 'mdi-delete-outline',
  },
])
