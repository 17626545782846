import { STATUSES } from '@/const/statuses.enum'

export default {
  filters: {
    getStatusTitle(val) {
      const currentStatus = STATUSES.filter((status) => status.id === val)
      if (currentStatus.length) {
        return currentStatus[0].title
      } else return val
    },
  },

  computed: {
    item() {
      return this.company || this.servicePoint || this.terminal || this.device || this.partner || this.employee || this.user
    },
    getStatus() {
      const currentStatus = STATUSES.filter((status) => status.id === this.item.status)
      if (currentStatus.length) {
        return currentStatus[0]
      } else return this.item.status
    },
  },

  methods: {
    getListStatusTitle(itemStatus) {
      const currentStatus = STATUSES.filter((status) => status.id === itemStatus)
      if (currentStatus.length) {
        return currentStatus[0].title
      } else return itemStatus
    },
    getListStatusColor(itemStatus) {
      const currentStatus = STATUSES.filter((status) => status.id === itemStatus)
      if (currentStatus.length) {
        return currentStatus[0].color
      } else return itemStatus
    },
  },
}
